import { History } from "history";
import qs from "query-string";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { trackSignUpClick } from "./popSignUpUtils";

export enum SignUpSource {
  CATNAV_SIGNUP_CTA = "catnav_signup_cta",
  AUTO_SEM_BOTTOM_BANNER = "auto-sem-bottom-banner",
  FAIRE_MARKET_SIGNUP_CTA = "faire_market_signup_cta",
}

export enum SignUpType {
  GENERIC = "1",
  AUTO = "auto",
  FACEBOOK = "facebook",
  PRODUCT = "product",
  BRAND = "brand",
  FAIRE_DIRECT = "direct",
  WIDGET = "widget",
  WIDGET_V2 = "widgetv2",
  FAIRE_DIRECT_REQUIRE_EMAIL = "directrequireemail",
  TRADESHOW = "tradeshow",
  RETAILER_SUBUSER = "retailersubuser",
  RETAILER_LEAD_ADS = "retailerleadads",
  FOI_BANNER = "foibanner",
  PAID_ADS = "paidads",
  SEM = "sem",
}

/**
 * This should be an exhaustive list of Faire Direct `signUp`
 * types. No other type should receive the Faire Direct credit.
 */
export const FaireDirectSignUpTypes: SignUpType[] = [
  SignUpType.FAIRE_DIRECT,
  SignUpType.FAIRE_DIRECT_REQUIRE_EMAIL,
  SignUpType.WIDGET,
  SignUpType.WIDGET_V2,
  SignUpType.TRADESHOW,
];

/**
 * Element list to identify which general part of a page
 * was interacted with to show signup modal
 */
export enum SignUpElement {
  HEADER = "HEADER",
  FOOTER = "FOOTER",
  HERO = "HERO",
  CATNAV = "CATNAV",
  NUDGE = "NUDGE",
  PRODUCT_GRID = "PRODUCT_GRID",
  CAROUSEL = "CAROUSEL",
  BELOW_FOLD = "BELOW_FOLD",
  TOP_BANNER = "TOP_BANNER",
  AUTO_POPUP = "AUTO_POPUP",
  LIST = "LIST",
  MODAL = "MODAL",
  SNACKBAR = "SNACKBAR",
}

type IPopSignUp = (
  type: SignUpType,
  trackingFunction: () => void,
  trackingParams: {
    element: SignUpElement;
    sourceId: string;
  },
  extraParams?: Record<string, string | undefined>
) => void;

type IPopSignUpWithHistory = (
  history: History,
  ...args: Parameters<IPopSignUp>
) => void;

export const popSignUp: IPopSignUpWithHistory = (
  history,
  type,
  trackingFunction,
  trackingParams,
  extraParams = {}
) => {
  trackingFunction();
  trackSignUpClick(type, trackingParams.element, trackingParams.sourceId);

  history.replace({
    ...history.location,
    search: qs.stringify({
      ...qs.parse(history.location.search),
      signUp: type,
      ...extraParams,
    }),
  });
};

export const usePopSignUp = (): {
  popSignUp: IPopSignUp;
} => {
  const history = useHistory();
  return {
    popSignUp: (type, trackingFunction, trackingParams, extraParams = {}) => {
      trackingFunction();
      trackSignUpClick(type, trackingParams.element, trackingParams.sourceId);

      history.replace({
        ...history.location,
        search: qs.stringify({
          ...qs.parse(history.location.search),
          signUp: type,
          ...extraParams,
        }),
      });
    },
  };
};

export const useTrackSignupModalOpenClick = () => {
  return useCallback(
    (type: SignUpType, element: SignUpElement, sourceId?: string) => {
      trackSignUpClick(type, element, sourceId);
    },
    []
  );
};
