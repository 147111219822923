import { NEXTJS_RSC_LOGGED_OUT_SEARCH_PAGE_240514 } from "@faire/web-api/indigofair/settings/NEXTJS_RSC_LOGGED_OUT_SEARCH_PAGE_240514";

import { getSettingValueWithoutAssignment } from "@faire/retailer/settings/__internal__/getSetting";

export const getSettingRscLoggedOutSearchPage = (
  rscParam: string | undefined
): boolean => {
  if (rscParam === "1") {
    return true;
  }
  if (rscParam === "0") {
    return false;
  }
  return getSettingValueWithoutAssignment(
    NEXTJS_RSC_LOGGED_OUT_SEARCH_PAGE_240514,
    false
  );
};
