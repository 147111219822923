import React, { createContext, useContext } from "react";

import { useRouter } from "@faire/retailer/app/_lib/routing/useRouter";

import { ModifiedNextRouter } from "./types";

type Context = {
  prefetch: ModifiedNextRouter["prefetch"];
  refresh: ModifiedNextRouter["refresh"];
};

const NextRouterContext = createContext<Context>({
  prefetch: () => {},
  refresh: () => {},
});

export const NextRouterContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const router = useRouter();
  return (
    <NextRouterContext.Provider value={router}>
      {children}
    </NextRouterContext.Provider>
  );
};

/**
 * This is a temporary hook that allows us to access the NextJS router.
 * In the legacy app, this will return `undefined`.
 *
 * Once we ship NextJS to 100%, we can remove this in favour of
 * `useRouter` from `next/router`.
 */
export const useNextRouterContext = () => useContext(NextRouterContext);
