import { execute as getShouldEdgeCache } from "@faire/web-api/app-status/should-edge-cache/get";
import { trackSessionsViewportRetailerPageViewView } from "@faire/web-api/events/sessions/view/viewportRetailerPageView";
import { trackWebRetailerServerRetailerEdgeCacheExperiment } from "@faire/web-api/events/webRetailerServer/actionUnknown/retailerEdgeCacheExperiment";
import { CurrencyStore } from "@faire/web/common/currency/CurrencyStore";
import { getDocument } from "@faire/web/common/globals/getDocument";
import { getWindowOrThrow } from "@faire/web/common/globals/getWindow";
import { useStrictLocalization } from "@faire/web/common/localization";
import useMemoryMonitor from "@faire/web/common/performance/trackMemoryUsage";
import { useAdblockDetector } from "@faire/web/ui/AdblockDetector";
import { useEffect } from "react";

import { useZustandLocation } from "@faire/retailer/app/_lib/routing/useZustandLocation";
import { setDefaultTitleTag } from "@faire/retailer/components/App/DefaultTitleTags";
import { configureCurrency } from "@faire/retailer/lib/currency";
import { getCurrencyConversionRate } from "@faire/retailer/serialized-data/getCurrencyConversionRate";
import { getSessionCountry } from "@faire/retailer/serialized-data/getSessionCountry";
import { getUser } from "@faire/retailer/serialized-data/getUser";
import { getSettingEdgeCacheAATest } from "@faire/retailer/settings/getSettingEdgeCacheAATest";
import { useRetailerStore } from "@faire/retailer/stores/domain/RetailerStore";
import { useCartInitialization } from "@faire/retailer/useCartInitialization";

import { useSynchronizePromoBanners } from "./useSynchronizePromoBanners";

export const AppEffects = ({
  trackEdgeCache,
}: {
  trackEdgeCache?: boolean;
}) => {
  useCartInitialization();
  useSynchronizePromoBanners();
  const currencyConversionRate = getCurrencyConversionRate();
  const sessionCountry = getSessionCountry();
  const { token: userToken } = getUser() ?? {};
  const { strictLocalize } = useStrictLocalization();
  const { pathname } = useZustandLocation((state) => state.pathname);

  useAdblockDetector(userToken);

  useEffect(() => {
    configureCurrency(sessionCountry, currencyConversionRate);
  }, [currencyConversionRate, sessionCountry]);

  const { isNewOrMigratedCanadian } = useRetailerStore([
    "isNewOrMigratedCanadian",
  ]);

  useEffect(() => {
    // TODO(bocong.zhao@faire.com, 2022/11/01): Remove this when we fully relaunch Canada
    // This is to clean up the localstorage if it's CAN with CAD (migrated Canadian retailer)
    if (isNewOrMigratedCanadian) {
      CurrencyStore.get().setCurrency(undefined);
    }
  }, [isNewOrMigratedCanadian]);

  useEffect(() => {
    setDefaultTitleTag(strictLocalize);
  }, [strictLocalize]);

  useEffect(() => {
    if (trackEdgeCache) {
      getWindowOrThrow().addEventListener("load", () => {
        setTimeout(() => {
          getShouldEdgeCache().then(() => {
            // Discard the first response because it definitely doesn't have the
            // cache cookie so no meaning to record the result.
            return getShouldEdgeCache({
              rawResponse: true,
            }).then((res) => {
              const cfCacheStatus = res.headers["cf-cache-status"];
              const isTreatment = getSettingEdgeCacheAATest();
              const cookie = getDocument()?.cookie;

              trackWebRetailerServerRetailerEdgeCacheExperiment(
                `${isTreatment}`,
                `${cfCacheStatus}`,
                `${cookie?.includes("api-edge-cache")}`,
                res.data
              );
            });
          });
        }, 5000);
      });
    }
  }, [trackEdgeCache]);

  /** Log viewport on path changge. */
  useEffect(() => {
    trackSessionsViewportRetailerPageViewView(
      pathname,
      `${getWindowOrThrow().innerWidth}`,
      `${getWindowOrThrow().innerHeight}`
    );
  }, [pathname]);

  useMemoryMonitor("web-retailer");

  return null;
};
