import { createContext } from "react";

/**
 * Global storage used during SSR.
 *
 * It can only be accessed syncronously, during a React render.
 */
export const SSRRequestLocalStorage = createContext<
  { global: unknown } | undefined
>(undefined);
