import debug from "debug";
import { History, Location } from "history";

import { RedirectRule } from "../initializers/redirectRules";

const logRedirect = debug("IF:REDIRECT");

export default class Redirects {
  private history?: History;

  constructor(private redirectRules: RedirectRule[]) {}

  private checkLocation = (location: Location) => {
    const redirectTarget = this.find(location);
    if (redirectTarget && this.history) {
      logRedirect(`From ${location.pathname} to ${redirectTarget}`);
      this.history.replace(redirectTarget);
    }
  };

  listen = (history: History) => {
    logRedirect("Listening");
    this.history = history;
    this.checkLocation(this.history.location);
    this.history.listen(this.checkLocation);
  };

  private find = (location: Location): string | null => {
    const pathname = decodeURIComponent(location.pathname);
    const query = decodeURIComponent(location.search);

    for (const rule of this.redirectRules) {
      const resolvedPath = rule(pathname, query);
      if (resolvedPath !== null) {
        return resolvedPath;
      }
    }

    return null;
  };
}
