import { ILoginRequest } from "@faire/web-api/indigofair/data/ILoginRequest";
import { createContextStore } from "@faire/web/ui/hooks/ContextStore";

type HCaptchaStore = {
  /** @param widgetID: ID of the rendered hCaptcha widget */
  widgetID?: HCaptchaId;
  /** @param error: Error in initializing hCaptcha */
  initializationError?: ILoginRequest.IHCaptchaData.HCaptchaError;
  /**
   * @param isHCaptchaEnabled: Whether hCaptcha widget (invisible) should be rendered or not.
   * We pay for each hCaptcha render. In order to only render it once per session, it
   * needs to be high up in the react tree. We only want to actually render it during
   * certain user journeys, such as sign-in; so this boolean enables these journeys to flag
   * that hCaptcha should be rendered.
   *
   * This state should only ever go from false -> true; never back to false.
   */
  isHCaptchaEnabled: boolean;
  /**
   * @param submittedTokens: Array used to store which tokens have been submitted.
   *
   * We only need to submit once per session per token,
   * and submitting multiple times may cause issues.
   *
   * This array should only grow; never shrink.
   */
  submittedTokens: string[];
};

const { useStore: useHCaptchaStore, Provider: HCaptchaStoreProvider } =
  createContextStore<HCaptchaStore>(
    {
      widgetID: undefined,
      initializationError: undefined,
      isHCaptchaEnabled: false,
      submittedTokens: [],
    },
    { name: "HCaptchaStore" }
  );

export { useHCaptchaStore, HCaptchaStoreProvider };
