import { useViewport } from "@faire/web/ui/hooks/useViewport";
import { ReactQueryDevtools as _ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React, { ComponentProps } from "react";

export const ReactQueryDevtools: React.FC<
  ComponentProps<typeof _ReactQueryDevtools>
> = (props) => {
  const { isTabletAndAbove } = useViewport();
  return isTabletAndAbove ? <_ReactQueryDevtools {...props} /> : null;
};
