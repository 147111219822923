import { HEADER_IS_APPAREL_RETAILER } from "@faire/retailer-shared/web-api/headers";
import { headerInterceptorFactory } from "@faire/web/common/requests/request-interceptors/headerInterceptor";

export const isApparelRetailerInterceptorFactory = (
  getIsApparelRetailer: () => boolean
) =>
  headerInterceptorFactory({
    header: HEADER_IS_APPAREL_RETAILER,
    value: () => String(getIsApparelRetailer()),
  });
