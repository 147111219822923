import { useCartPageStore } from "@faire/retailer/features/Cart/CartPageStore";
import { useSettingCartRSC } from "@faire/retailer/settings/useSettingCartRSC";

import { useIsOnCartPage } from "./useIsOnCartPage";

/**
 * For the RSC experience, the initial page content is rendered on the server.
 * The cart query data can be "lazy" fetched on the client, so we disable the queries
 * until the page is loaded.
 */
export const useShouldDisableCartQueries = () => {
  const isCartRSC = useSettingCartRSC();
  const [{ hasPageLoaded }] = useCartPageStore(["hasPageLoaded"]);
  const isOnCartPage = useIsOnCartPage();

  return isCartRSC && isOnCartPage && !hasPageLoaded;
};
