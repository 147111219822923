import { IImage } from "@faire/web-api/indigofair/data/IImage";
import { IProductPageResponse } from "@faire/web-api/indigofair/data/IProductPageResponse";
import { route as homeRoute } from "@faire/web-api/routes/root";
import { QueryParameters as SignUpQueryParameters } from "@faire/web-api/routes/welcome/r/personal";
import { pathMatchesRoute } from "@faire/web/common/routes/RouteMatcher";
import { isNotUndefined } from "@faire/web/common/typescriptUtils";
import { Location } from "history";

import { SignUpType } from "@faire/retailer/lib/popSignUp";
import { ElevatePortalStore } from "@faire/retailer/stores/domain/ElevatePortalStore";

import { AppSignUpPageResults } from "./AppSignUpPageResultsProvider";

export const enum AppSignUpModalVariant {
  DEFAULT = "default",
  AUTO = "auto",
  AUTO_WITH_IMAGE = "auto_with_image",
  PRODUCT = "product",
  FACEBOOK = "facebook",
  FAIRE_DIRECT = "faire_direct",
  FAIRE_DIRECT_EMAIL_INVITATION = "faire_direct_email_invitation",
}

export function determineAppSignUpModalVariant(
  signUpParams: SignUpQueryParameters,
  location: Location,
  productPageResponse: IProductPageResponse | undefined
): AppSignUpModalVariant {
  if (
    ElevatePortalStore.get().isFaireDirectActiveInLoggedOutWithPageLocation(
      location,
      productPageResponse
    )
  ) {
    return isNotUndefined(signUpParams.email)
      ? AppSignUpModalVariant.FAIRE_DIRECT_EMAIL_INVITATION
      : AppSignUpModalVariant.FAIRE_DIRECT;
  } else if (
    signUpParams.signUp === SignUpType.FACEBOOK &&
    isNotUndefined(signUpParams.refP)
  ) {
    return AppSignUpModalVariant.FACEBOOK;
  } else if (
    signUpParams.signUp === SignUpType.PRODUCT &&
    isNotUndefined(signUpParams.product)
  ) {
    return AppSignUpModalVariant.PRODUCT;
  } else if (signUpParams.signUp === SignUpType.AUTO) {
    const isHomepage = pathMatchesRoute(location, homeRoute);
    return isHomepage
      ? AppSignUpModalVariant.AUTO
      : AppSignUpModalVariant.AUTO_WITH_IMAGE;
  } else {
    return AppSignUpModalVariant.DEFAULT;
  }
}

export function isFaireDirectVariant(variant: AppSignUpModalVariant): boolean {
  return (
    variant === AppSignUpModalVariant.FAIRE_DIRECT ||
    variant === AppSignUpModalVariant.FAIRE_DIRECT_EMAIL_INVITATION
  );
}

export function isAutoSignUpVariant(variant: AppSignUpModalVariant): boolean {
  return (
    variant === AppSignUpModalVariant.AUTO ||
    variant === AppSignUpModalVariant.AUTO_WITH_IMAGE
  );
}

type ModalImageryType =
  | "image-from-product-token"
  | "featured-product-image"
  | "no-image";
export function getModalImageryType(
  variant: AppSignUpModalVariant,
  isSettingSignUpImageryTreatment: boolean
): ModalImageryType {
  if (variant === AppSignUpModalVariant.AUTO_WITH_IMAGE) {
    return "featured-product-image";
  } else if (
    variant === AppSignUpModalVariant.FACEBOOK ||
    variant === AppSignUpModalVariant.PRODUCT
  ) {
    return "image-from-product-token";
  } else if (
    variant === AppSignUpModalVariant.DEFAULT &&
    isSettingSignUpImageryTreatment
  ) {
    return "featured-product-image";
  } else {
    return "no-image";
  }
}

export function getModalImageryFromPageResults(
  modalImageryType: ModalImageryType,
  pageResults: AppSignUpPageResults,
  productToken: string | undefined
): IImage | undefined {
  if (modalImageryType === "featured-product-image") {
    if (pageResults.productTiles) {
      return pageResults.productTiles[0]?.best_image;
    } else if (pageResults.suppliersPageBrands) {
      return pageResults.suppliersPageBrands[0]?.image;
    } else if (pageResults.pdpProduct) {
      return pageResults.pdpProduct.visuals?.images[0];
    } else if (pageResults.productBasedCollection) {
      return pageResults.productBasedCollection.image;
    }
  } else if (modalImageryType === "image-from-product-token" && productToken) {
    if (pageResults.productTiles) {
      return pageResults.productTiles?.find(
        (p) => p?.product?.token === productToken
      )?.best_image;
    } else if (pageResults.suppliersPageBrands) {
      const brand = pageResults.suppliersPageBrands.find(
        (brand) =>
          Object.values(brand.image_tokens_to_product_tokens).indexOf(
            productToken
          ) >= 0
      );
      if (brand) {
        return brand.product_images.find(
          (image) =>
            brand.image_tokens_to_product_tokens[image.token || ""] ===
            productToken
        );
      }
    } else if (pageResults.pdpProduct) {
      if (pageResults.pdpProduct.token === productToken) {
        return pageResults.pdpProduct.visuals?.images[0];
      }
    }
  }

  return undefined;
}

export const APP_SIGN_UP_CURATED_IMAGES = [
  "https://cdn.faire.com/fastly/75bd57ce49c05b0665c0e36d102232bdd91c8cec18634fc11739a400cee9894b.jpeg",
  "https://cdn.faire.com/fastly/67badc01e22b37e8928a36287995e15c42c942e7198dbf61c257916dc80276ab.jpeg",
  "https://cdn.faire.com/fastly/1425d855258b97ff9134287852b0dbc14df69357e65dfb81a24d12b86ecc625a.jpeg",
];
