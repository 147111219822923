import { Core } from "@faire/design-tokens";
import { IAlertBanner } from "@faire/web-api/indigofair/data/IAlertBanner";

import { AlertBannerStore } from "@faire/retailer/packages/core/services/AlertBanner/AlertBannerStore";

export const handleComponentLoadError = (e: Error) => {
  AlertBannerStore.get().setClientSideBanner(
    IAlertBanner.build({
      background_color_hex: Core.surface.critical,
      dismissable: true,
      message:
        "Sorry, something went wrong. Please check your internet connection and try again.",
      text_color_hex: "#fff",
      entities: {},
    })
  );
  throw e;
};
