import { lazy } from "react";

import { handleComponentLoadError } from "@faire/retailer/lib/loadChunkErrors";

export const LazyMobileAppSnackbar = lazy(() =>
  import(
    /* webpackChunkName: "retailer-mobile-app-snackbar" */ "@faire/retailer/components/MobileAppSnackbar/MobileAppSnackbar"
  )
    .then((m) => ({ default: m.MobileAppSnackbar }))
    .catch(handleComponentLoadError)
);
