import { isWindowUndefined } from "@faire/web/common/server-side-rendering/isWindowUndefined";
import { singletonGetter } from "@faire/web/common/singletons/getter";
import { WebSocketMessageChannel } from "@faire/web/messenger/MessageChannel/WebSocketMessageChannel";

import { convertToSwitchBoardUrl } from "@faire/retailer/lib/webSocketUrls";

export class WebSocketChannelStore {
  /**
   * @trackfunction
   */
  static get = singletonGetter(WebSocketChannelStore);

  private channelByUrl: Record<string, WebSocketMessageChannel | undefined> =
    {};

  private hiddenChannelByUrl: Record<
    string,
    WebSocketMessageChannel[] | undefined
  > = {};

  getOrInitChannel = (url: string): WebSocketMessageChannel | undefined => {
    if (isWindowUndefined()) {
      return undefined;
    }

    const channel = this.channelByUrl[url] ?? new WebSocketMessageChannel(url);
    this.channelByUrl[url] = channel;
    return channel;
  };

  closeChannel = (url: string): void => {
    this.channelByUrl[url]?.close();
    this.channelByUrl[url] = undefined;

    const switchBoardUrl = convertToSwitchBoardUrl(url);
    this.hiddenChannelByUrl[switchBoardUrl]?.forEach((channel) =>
      channel.close()
    );
    this.hiddenChannelByUrl[switchBoardUrl] = undefined;
  };
}
