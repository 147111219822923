import { useViewport } from "@faire/web/ui/hooks/useViewport";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { MobileAppSnackbarWrapper } from "@faire/retailer/components/App/MobileAppSnackbarWrapper";
import { AppDownloadNudgeWrapper } from "@faire/retailer/components/AppDownloadNudge/AppDownloadNudgeWrapper";
import { useIsLoggedInRetailer } from "@faire/retailer/lib/isLoggedInRetailer";
import { getIsAppInstalled } from "@faire/retailer/serialized-data/getIsAppInstalled";
import {
  useAssignSettingNuxAppDownloadNudges,
  useSettingNuxAppDownloadNudges,
} from "@faire/retailer/settings/useSettingNuxAppDownloadNudges";

import { isAppDownloadPath } from "./appDownloadWrapperUtils";

export const AppDownloadWrapper = () => {
  const { pathname } = useLocation();
  const { isMobile } = useViewport();
  const isLoggedInRetailer = useIsLoggedInRetailer();
  const isNuxAppDownloadNudgesEnabled = useSettingNuxAppDownloadNudges();
  const assignSettingNuxAppDownloadNudges =
    useAssignSettingNuxAppDownloadNudges();

  const shouldAssign =
    isMobile &&
    isLoggedInRetailer &&
    !getIsAppInstalled() &&
    isAppDownloadPath(pathname);

  useEffect(() => {
    if (shouldAssign) {
      assignSettingNuxAppDownloadNudges();
    }
  }, [assignSettingNuxAppDownloadNudges, shouldAssign]);

  return isNuxAppDownloadNudgesEnabled ? (
    <AppDownloadNudgeWrapper />
  ) : (
    <MobileAppSnackbarWrapper />
  );
};
