import { getSettingNextJSRSCBrandFirstCategoryPageBlockList } from "@faire/retailer/settings/getSettingNextJSRSCBrandFirstCategoryPageBlockList";
import { getSettingNextJSRSCProductFirstCategoryPageAllowList } from "@faire/retailer/settings/getSettingNextJSRSCProductFirstCategoryPageAllowList";
import { getSettingSDUICategoryPagePathNavigationPaths } from "@faire/retailer/settings/getSettingSDUICategoryPagePathNavigationPaths";

export const isRSCCategoryNavigationPath = (
  navigationPath: string
): boolean => {
  const productFirstC1CategoryUrlIds =
    getSettingNextJSRSCProductFirstCategoryPageAllowList();
  const seasonalHubUrlIds = getSettingSDUICategoryPagePathNavigationPaths();
  const brandFirstUrlIds = getSettingNextJSRSCBrandFirstCategoryPageBlockList();
  const isRootCategorySupportedByRSC =
    productFirstC1CategoryUrlIds.some((urlId) =>
      navigationPath.startsWith(urlId)
    ) || seasonalHubUrlIds.some((urlId) => navigationPath.startsWith(urlId));
  const isCurrentCategoryBrandFirst = brandFirstUrlIds.some((urlId) =>
    navigationPath.endsWith(urlId)
  );

  return isRootCategorySupportedByRSC && !isCurrentCategoryBrandFirst;
};
