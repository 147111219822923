import { logError } from "@faire/web/common/logging";

import { ILeanCartItem } from "@faire/retailer/stores/domain/CartStore/consts";

export const findItemByOptionTokenAndCustomizations = (
  token: string,
  customizations: Map<string, string>,
  items: ILeanCartItem[]
): ILeanCartItem | undefined => {
  const filteredItems = items.filter(
    (item) =>
      item.product_option_token === token &&
      item.customizations.length === customizations.size &&
      item.customizations?.every(
        (customization) =>
          customizations.get(
            customization.product_customization_token || ""
          ) === customization.value
      )
  );

  if (filteredItems.length > 1) {
    logError(
      `Found multiple results when filtering by product option and customizations. productOptionToken=${token} productTokens=[${items
        .map((item) => item.product_token || "")
        .join(", ")}]`
    );
  }

  return filteredItems[0];
};
