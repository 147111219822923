import { buildCategoryNavigationPath } from "@faire/retailer-shared/categories/navigationPath";
import { QueryParameters as CategoryQueryParameters } from "@faire/web-api/routes/category/c1CategoryUrlId";
import { match as matchC1CategoryBrandValueRoute } from "@faire/web-api/routes/category/c1CategoryUrlId/brand-value/brandValue/match";
import { match as matchC1CategoryRoute } from "@faire/web-api/routes/category/c1CategoryUrlId/match";
import { match as matchC2CategoryBrandValueRoute } from "@faire/web-api/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/brand-value/brandValue/match";
import { match as matchC3CategoryBrandValueRoute } from "@faire/web-api/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/c3CategoryUrlId/brand-value/brandValue/match";
import { match as matchC3CategoryRoute } from "@faire/web-api/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/c3CategoryUrlId/match";
import { match as matchC2CategoryRoute } from "@faire/web-api/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/match";
import { getLocationOrThrow } from "@faire/web/common/globals/getLocation";

import { isLoggedInRetailer } from "@faire/retailer/lib/isLoggedInRetailer";
import { getSettingNextJSRscCategoryPage } from "@faire/retailer/settings/useSettingNextJSRscCategoryPage";
import { isRSCCategoryNavigationPath } from "@faire/retailer/views/category/HubsRSC/isRSCCategoryNavigationPath";

const supportedRoutesMatch = [
  matchC3CategoryBrandValueRoute,
  matchC2CategoryBrandValueRoute,
  matchC1CategoryBrandValueRoute,
  matchC3CategoryRoute,
  matchC2CategoryRoute,
  matchC1CategoryRoute,
];
export const getShouldRequestRSCOnCategoryPage = (options: {
  prevPathname: string;
  prevSearch: string;
  pathname: string;
  search: string;
}) => {
  const { prevPathname, prevSearch, pathname, search } = options;
  return (
    shouldShowRSCOnCategoryPage() &&
    isCategoryPageRoute(pathname, search) &&
    isProductFirstOrHubCategoryPage(pathname) &&
    buildCategoryPageLoadKey(prevPathname, prevSearch) !==
      buildCategoryPageLoadKey(pathname, search)
  );
};

const isProductFirstOrHubCategoryPage = (pathname: string) => {
  const urlIds = pathname
    .split("/")
    .filter(
      (urlId) => urlId !== "" && urlId !== "category" && urlId !== "subcategory"
    )
    .map((urlId) => decodeURIComponent(urlId));
  const navigationPath = buildCategoryNavigationPath(urlIds);
  return isRSCCategoryNavigationPath(navigationPath);
};

const shouldShowRSCOnCategoryPage = () => {
  const search = getLocationOrThrow().search;
  const rscParam = (
    CategoryQueryParameters.parse(search) as Record<string, string | undefined>
  ).rsc;
  return getSettingNextJSRscCategoryPage(rscParam) && isLoggedInRetailer();
};

const isCategoryPageRoute = (pathname: string, search: string) => {
  if (!pathname.endsWith("/")) {
    pathname += "/";
  }
  return supportedRoutesMatch.some(
    (match) => match(`${pathname}${search}`) !== null
  );
};

const buildCategoryPageLoadKey = (pathname: string, search: string) => {
  const queryParam = CategoryQueryParameters.parse(search);
  return `${pathname}-${queryParam.filters}-${queryParam.page}`;
};
