import { IProductPageResponse } from "@faire/web-api/indigofair/data/IProductPageResponse";
import { Path } from "@faire/web/common/Path";
import { Location } from "history";

import { getFaireDirectBrandToken } from "@faire/retailer/serialized-data/getFaireDirectBrandToken";

import {
  isPDP,
  isValidFaireDirectPath,
  isValidFaireDirectPathWithoutQualification,
} from "./VALID_LOGGED_OUT_FAIRE_DIRECT_ROUTES";

export const determineIsFaireDirectActiveInLoggedOut = (location: Location) => {
  const faireDirectBrandToken = getFaireDirectBrandToken() ?? "";
  const pathname = location.pathname;

  if (!isValidFaireDirectPath(pathname, faireDirectBrandToken)) {
    return false;
  }

  if (isValidFaireDirectPathWithoutQualification(pathname)) {
    return true;
  }

  /**
   * Non-Faire Direct products should not be Faire Direct active
   * so that the non-Faire Direct sign-up modal is shown.
   */
  if (isPDP(pathname) && !!faireDirectBrandToken) {
    const brandToken = new Path(location).searchParams.get("brand");

    if (brandToken && faireDirectBrandToken !== brandToken) {
      return false;
    }
  }

  return !!faireDirectBrandToken;
};

export const handleFaireDirectSignUpFromMarketplaceAssignment = (
  location: Location,
  assignFaireDirectSignUpFromMarketplace: () => void,
  productPageResponse: IProductPageResponse | undefined
): void => {
  const faireDirectBrandToken = getFaireDirectBrandToken() ?? "";
  const pathname = location.pathname;

  if (!isValidFaireDirectPath(pathname, faireDirectBrandToken)) {
    const signUp = new Path(location).searchParams.get("signUp");

    if (signUp && !!faireDirectBrandToken) {
      // Run assignment on signUp modal pop for invalid routes.
      assignFaireDirectSignUpFromMarketplace();
      return;
    }
  }

  if (isPDP(pathname) && !!faireDirectBrandToken) {
    const brandToken = new Path(location).searchParams.get("brand");

    // Assign for non-Faire Direct products on FD PDP.
    if (brandToken && faireDirectBrandToken !== brandToken) {
      assignFaireDirectSignUpFromMarketplace();
      return;
    }

    // Run assignment on non-FD PDP.
    const brandTokenAssociatedWithPDP = productPageResponse?.brand?.token;

    if (
      brandTokenAssociatedWithPDP &&
      brandTokenAssociatedWithPDP !== faireDirectBrandToken
    ) {
      assignFaireDirectSignUpFromMarketplace();
      return;
    }
  }
};
