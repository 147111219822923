import { route as brandPageRoute } from "@faire/web-api/routes/brand/brandToken";
import { RetailerRoute } from "@faire/web/common/routes/RetailerRouteMatcher";
import { RouteMatcher } from "@faire/web/common/routes/RouteMatcher";
import { Storage } from "@faire/web/common/Storage";
import { removeLocaleFromUrl } from "@faire/web/ui/routing/util";
import { useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";

import { useIsLoggedInRetailer } from "@faire/retailer/lib/isLoggedInRetailer";
import { BANNER_FLAG_TIMESTAMP } from "@faire/retailer/services/MobileAppBanner";
import { getSettingAppMigrationAvailable } from "@faire/retailer/settings/getSettingAppMigrationAvailable";
import {
  MOBILE_APP_PROMOTION_ALLOWED_PATHS,
  MOBILE_APP_PROMOTION_BLOCKED_PATHS,
} from "@faire/retailer/stores/ui/PromoModalStore";

export const useShouldShowMobileAppBanner = () => {
  const { pathname } = useLocation();
  const isLoggedInRetailer = useIsLoggedInRetailer();

  const brandPageMatcher: RouteMatcher<RetailerRoute> = new RouteMatcher([
    brandPageRoute,
  ]);
  const allowedRouteMatcher: RouteMatcher<RetailerRoute> = new RouteMatcher(
    MOBILE_APP_PROMOTION_ALLOWED_PATHS
  );
  const localeRemovedPathname = useMemo(
    () => removeLocaleFromUrl(pathname),
    [pathname]
  );

  const isBrandPagePartialBlock =
    brandPageMatcher.match(pathname) && isLoggedInRetailer;
  const isLocationOnAllowedList = !!allowedRouteMatcher.match(pathname);
  const isLocationOnBlockedList = MOBILE_APP_PROMOTION_BLOCKED_PATHS.some(
    (path) => !!matchPath(localeRemovedPathname, { path, exact: true })
  );

  const isLocationAllowed =
    !isBrandPagePartialBlock &&
    isLocationOnAllowedList &&
    !isLocationOnBlockedList;

  const dismissedUntil = Storage.local.getItem(BANNER_FLAG_TIMESTAMP);
  const isDismissed = !!dismissedUntil && Number(dismissedUntil) >= Date.now();

  return (
    isLoggedInRetailer &&
    isLocationAllowed &&
    !isDismissed &&
    getSettingAppMigrationAvailable()
  );
};
