import { QueryParameters as SearchQueryParameters } from "@faire/web-api/routes/search";
import { getLocationOrThrow } from "@faire/web/common/globals/getLocation";

import { isLoggedInRetailer } from "@faire/retailer/lib/isLoggedInRetailer";
import { getSettingRscLoggedOutSearchPage } from "@faire/retailer/settings/getSettingRscLoggedOutSearchPage";

export const shouldShowRscLoggedOutSearchPage = () => {
  const search = getLocationOrThrow().search;
  const rscParam = (
    SearchQueryParameters.parse(search) as Record<string, string | undefined>
  ).rsc;
  return getSettingRscLoggedOutSearchPage(rscParam) && !isLoggedInRetailer();
};
