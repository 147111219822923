export interface IUrl {
  pathname?: string;
  search?: string;
  hash?: string;
}

export const sanitizeUrl = ({
  pathname = "",
  search = "",
  hash = "",
}: IUrl): string => {
  let sanitizedPathname = pathname;
  let sanitizedSearch = search;
  // Ensure there is a leading slash
  if (sanitizedPathname[0] !== "/") {
    sanitizedPathname = `/${sanitizedPathname}`;
  }
  // Ensure there is a leading question mark in search
  if (sanitizedSearch && sanitizedSearch[0] !== "?") {
    sanitizedSearch = `?${sanitizedSearch}`;
  }
  return `${sanitizedPathname}${sanitizedSearch}${hash}`;
};
