import { getDocumentOrThrow } from "@faire/web/common/globals/getDocument";

/**
 * @returns true if the referrer is facebook or instagram
 */
export const isComingFromFacebookOrInstagram = (): boolean => {
  return ["facebook", "instagram"].some((ref) =>
    getDocumentOrThrow().referrer.includes(ref)
  );
};
