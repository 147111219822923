import { logError } from "@faire/web/common/logging";

export function setTimeoutIfPossible(
  handler: TimerHandler,
  timeout?: number,
  // eslint-disable-next-line
  ...argumentsArray: any[]
): number | undefined {
  // eslint-disable-next-line @faire/ssr-no-restricted-globals
  if (typeof window === "undefined") {
    // window object is undefined in server environment, the JSDOM window mock
    // is attached to ssrZone
    if (!("Zone" in globalThis)) {
      // if there is Zone.js then this is next.js server
      throw new Error("Next.js doesn't allow setTimeout");
    } else {
      // Otherwise we make this no-ops but log to Datadog for clean-ups
      logError(new Error("SSR shouldn't allow setTimeout"));
      return undefined;
    }
  } else {
    // eslint-disable-next-line @faire/ssr-no-restricted-globals
    return window.setTimeout(handler, timeout, argumentsArray);
  }
}
