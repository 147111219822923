"use client";

import { useServerInsertedHTML } from "next/navigation";
import React, { useContext, useLayoutEffect, useState } from "react";
import {
  ServerStyleSheet,
  StyleSheetManager,
  StyleSheetContext,
} from "styled-components";

/**
 * On the server, inserts styled-component styles into the document.
 */
export const StyledComponentsRegistry = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  // Only create stylesheet once with lazy initial state
  // x-ref: https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
  const [styledComponentsStyleSheet] = useState(() => new ServerStyleSheet());

  const { styleSheet: clientStyleSheet } = useContext(StyleSheetContext);

  useLayoutEffect(() => {
    // eslint-disable-next-line @faire/ssr-no-restricted-globals
    window.SS_SC = clientStyleSheet;
    clientStyleSheet.rehydrate();
  }, [clientStyleSheet]);

  // eslint-disable-next-line @faire/no-inline-hoc-fc -- false positive
  useServerInsertedHTML(() => {
    const elements = styledComponentsStyleSheet.getStyleElement();
    styledComponentsStyleSheet.instance.clearTag();

    const modifiedElements = elements.map((element, index) => {
      return (
        <React.Fragment key={`serverstylesheet-style-${index}`}>
          {element}
          {/*
           * Rehydrate styles on the client after the intial rehydration performed by styled-components
           * When server insert new styles after rehydration, we need to rehydrate again to consolidate
           * the styles and avoid conflicts.
           */}
          <script
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{
              // eslint-disable-next-line @faire/no-dollar-symbol-in-strings
              __html: `var _window$SS_SC, _window$SS_SC$rehydra;
            (_window$SS_SC = window.SS_SC) === null || _window$SS_SC === void 0 || (_window$SS_SC$rehydra = _window$SS_SC.rehydrate) === null || _window$SS_SC$rehydra === void 0 ? void 0 : _window$SS_SC$rehydra.call(_window$SS_SC);`,
            }}
          ></script>
        </React.Fragment>
      );
    });

    return <>{modifiedElements}</>;
  });

  // eslint-disable-next-line @faire/ssr-no-restricted-globals
  if (typeof window !== "undefined") {
    return (
      <StyleSheetManager enableVendorPrefixes>{children}</StyleSheetManager>
    );
  }

  return (
    <StyleSheetManager
      enableVendorPrefixes
      sheet={styledComponentsStyleSheet.instance}
    >
      {children}
    </StyleSheetManager>
  );
};
