import { NEXTJS_RSC_PRODUCT_TILE_LOGGED_IN_SEARCH_PAGE_240729 } from "@faire/web-api/indigofair/settings/NEXTJS_RSC_PRODUCT_TILE_LOGGED_IN_SEARCH_PAGE_240729";
import { getGlobalProperty } from "@faire/web/common/globals/getGlobalProperty";

import { getSettingValueWithoutAssignment } from "@faire/retailer/settings/__internal__/getSetting";

export const getSettingRscLoggedInSearchPage = (
  rscParam: string | undefined
): boolean => {
  if (rscParam === "1") {
    return true;
  }
  if (rscParam === "0" || !getGlobalProperty("isNextJS", false)) {
    return false;
  }
  return getSettingValueWithoutAssignment(
    NEXTJS_RSC_PRODUCT_TILE_LOGGED_IN_SEARCH_PAGE_240729,
    false
  );
};
