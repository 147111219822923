"use client";

import { QueryParameters as CartPageQueryParams } from "@faire/web-api/routes/cart";
import { getWindow } from "@faire/web/common/globals/getWindow";
import { NoSSR } from "@faire/web/common/NoSSR";
import React, { Suspense, use, useContext, useMemo, useRef } from "react";

import { SDUIUPLTContext } from "@faire/retailer/components/SDUI/UPLT/SDUIUPLTContext";

import { INITIAL_CART_PAGE_STORE } from "./consts";
import { Provider, useStore } from "./store";
import { LazySynchronizer } from "./Synchronizer";

// eslint-disable-next-line @faire/no-re-exports
export const useCartPageStore = useStore;

type Props = React.ComponentProps<typeof Provider>;

export const CartPageStoreProvider: React.FC<Props> = ({ value, children }) => {
  const brandCartRefs = useRef<{ [brandToken: string]: HTMLDivElement | null }>(
    {}
  );
  const { query } = CartPageQueryParams.parse(getWindow()?.location.search);

  const initialValue = useMemo(() => {
    const base = { brandCartRefs, searchFieldValue: query ?? "" };
    return value
      ? { ...value, ...base }
      : { ...INITIAL_CART_PAGE_STORE, ...base };
  }, [query, value]);

  return (
    <Provider value={initialValue}>
      <NoSSR>
        <Suspense fallback={null}>
          <Synchronizer />
        </Suspense>
      </NoSSR>
      {children}
    </Provider>
  );
};

const Synchronizer: React.FC = () => {
  const { renderNonPrimaryContentPromise } = useContext(SDUIUPLTContext);
  use(renderNonPrimaryContentPromise);
  return (
    <Suspense fallback={null}>
      <LazySynchronizer />
    </Suspense>
  );
};
