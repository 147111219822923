import { useSearchParams } from "next/navigation";

/**
 * URLSearchParams.toString() replaces spaces with "+" instead of "%20"
 * This function replaces "+" with "%20" to match the behavior of encodeURI
 */
export const useSearchParamsString = () => {
  const searchParams = useSearchParams();
  return searchParams.toString().replace(/\+/g, "%20");
};
