import { getLocationOrThrow } from "@faire/web/common/globals/getLocation";
import { isLocal } from "@faire/web/common/user-agent/isLocal";

import {
  getIsPrerelease,
  getIsProduction,
  getIsStaging,
} from "@faire/retailer/serialized-data/getEnvName";

export const getFaireBaseUrl = (): string => {
  if (getIsProduction()) {
    return "faire.com";
  } else if (getIsPrerelease()) {
    return "prerelease.faire.dev";
  } else if (getIsStaging()) {
    return "faire-stage.com";
  } else if (isLocal()) {
    return "localhost";
  }

  return getLocationOrThrow().hostname;
};
