import { useEffect } from "react";

import { useMarketStore } from "@faire/retailer/stores/domain/Events/FaireMarkets/MarketStore";
import { usePromoBanners } from "@faire/retailer/stores/ui/usePromoBanners";

import { PromoBannerType } from "../Notifications/PromoBanner/bannerOrder";

export const useSynchronizePromoBanners = () => {
  const { banners } = usePromoBanners();
  const { setHasMarketCountdownBanner } = useMarketStore([
    "setHasMarketCountdownBanner",
  ]);

  useEffect(() => {
    setHasMarketCountdownBanner(
      banners && banners[0] === PromoBannerType.MARKET_COUNTDOWN
    );
  }, [banners, setHasMarketCountdownBanner]);

  return null;
};
