"use client";

import { createContextStore } from "@faire/web/ui/hooks/ContextStore";
import * as React from "react";

import { SDUIUPLTComponentsPerClientSize } from "./calculateNumComponentsPerClientSize";

interface SDUIUPLTState {
  refCallback?: React.RefCallback<HTMLElement>;
  numComponents?: number;
  renderNonPrimaryContentPromise: Promise<void>;
}

export const SDUIUPLTContext = React.createContext<SDUIUPLTState>({
  refCallback: emptyRefCallback,
  numComponents: undefined,
  renderNonPrimaryContentPromise: new Promise(() => {}),
});

interface SDUIUPLTLoadedState {
  numComponentsPerClientSize?: SDUIUPLTComponentsPerClientSize;
}

export const {
  useStore: useUPLTLoadedStore,
  Provider: UPLTLoadedStoreProvider,
} = createContextStore<SDUIUPLTLoadedState>(
  {
    numComponentsPerClientSize: undefined,
  },
  { name: "SDUIUPLTLoadedStore" }
);

function emptyRefCallback() {}
