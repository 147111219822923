import { trackSignupOpenModalClick } from "@faire/web-api/events/signup/click/openModal";
import { getLocationOrThrow } from "@faire/web/common/globals/getLocation";
import { Path } from "@faire/web/common/Path";

import { SignUpElement, SignUpType } from "./popSignUp";

export function getSignUpLink(type: SignUpType) {
  const location = getLocationOrThrow();
  return new Path(location).replace({ signUp: type }).toString();
}

export function trackSignUpClick(
  type: SignUpType,
  element: SignUpElement,
  sourceId: string | undefined
) {
  const location = getLocationOrThrow();
  const signUpType = type === SignUpType.GENERIC ? "generic" : type;

  trackSignupOpenModalClick(
    signUpType,
    location.pathname + location.search,
    sourceId ?? "",
    element
  );
}
