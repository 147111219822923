export const SHOW_MODAL_QUERY_PARAM = "show_modal";

export const SIGN_UP_PARAM_KEY = "signUp";

export const IDENTITY_VERIFICATION_SHOW_MODAL_QUERY_PARAM_VALUE =
  "identity_verification_modal";

export const SAMPLE_PROSPECTING_SIGN_IN_QUERY_PARAM_VALUE = "preSample";
export const SAMPLE_PROSPECTING_POST_SAMPLE_QUERY_PARAM_VALUE = "postSample";
export const SAMPLE_PROSPECTING_SAMPLE_REQUEST_COMPLETE_VALUE =
  "postSampleRequest";

export const IDENTITY_VERIFICATION_MODAL_LINK_TO_QUERY_PARAM = "link_to";
export const IDENTITY_VERIFICATION_MODAL_BRAND_NAME_QUERY_PARAM = "brand";

export const FAIRE_DIRECT_FOI = "faire_direct_foi";

export const FAIRE_DIRECT_MARKETPLACE_INCENTIVE_MODAL =
  "faire_direct_marketplace_incentive";

export const FAIRE_DIRECT_CREDIT_FAQ = "faire_direct_credit_faq";

export const FIRST_ORDER_INCENTIVE_MODAL = "first_order_incentive_modal";

export const SHOPIFY_PLAN_UPSELL_MODAL = "shopify_plan_upsell_modal";

export const FREE_RETURNS_MODAL_QUERY_PARAM_VALUE = "free_returns_info";

export const SAMPLES_REQUEST_MODAL = "samples_request";
export const SAMPLES_REQUEST_MODAL_BRAND_TOKEN_QUERY_PARAM =
  "samples_request_brand";
export const SAMPLES_REQUEST_MODAL_SOURCE_QUERY_PARAM =
  "samples_request_source";

export const TEAM_MEMBERS_MODAL_QUERY_PARAM = "team_members";

export const DIGEST_ENTRYPOINT_MODAL_QUERY_PARAM = "drops";

export const PROFILE_PICTURE_UPLOAD_MODAL_QUERY_PARAM =
  "profile_picture_upload";

// deprecated - old IMP modal to be removed
export const CONDUCTOR_FUNCTIONAL_MODAL = "conductor_functional_modal";

// for Conductor to identify the modal
export const MODAL_IDENTIFIER_QUERY_PARAM = "modal_identifier";

export const RESELLER_CERTIFICATE_MODAL = "reseller_certificate_modal";

export const RESET_PASSWORD_QUERY_PARAM = "resetPassword";
