import { ILoginRequest } from "@faire/web-api/indigofair/data/ILoginRequest";
import { logError } from "@faire/web/common/logging";

export const hCaptchaQueryKey = (widgetID: string | undefined) => [
  "hcaptcha-token",
  widgetID,
];

export const logHCaptchaError = (
  error: unknown,
  type: ILoginRequest.IHCaptchaData.HCaptchaError
) =>
  logError(error, {
    tags: { source: "hCaptcha", type },
  });
