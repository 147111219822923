import { Action, Location, History } from "history";

const pathContainsMaker = (path: string): boolean =>
  path.toLocaleLowerCase().includes("/maker") &&
  !path.toLocaleLowerCase().includes("/makers");

const replaceMakerWithBrandInPath = (path: string): string =>
  path.replace(/maker/gi, "brand");

export const initializeBrandRedirects = async (history: History) => {
  // As of 24-03-2021 all paths containing the substring "maker" are renamed to contain "brand" and redirected accordingly
  const checkAndRedirectPath = (location: Location, action: Action) => {
    if (action === "POP" || !pathContainsMaker(history.location.pathname)) {
      return;
    }
    const { pathname, search, hash } = location;
    const updatedUrl = replaceMakerWithBrandInPath(pathname);
    history.replace(`${updatedUrl}${search}${hash}`);
  };
  history?.listen(checkAndRedirectPath);
  // Check inital path for redirect as well
  checkAndRedirectPath(history.location, "PUSH");
};
