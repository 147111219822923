import { useIsSSR } from "@faire/web/common/server-side-rendering/isSSR";
import React, { Suspense } from "react";

import { LazyDevTools } from "@faire/retailer/components/DevTools";
import { isE2E } from "@faire/retailer/lib/isE2E";
import { getIsProduction } from "@faire/retailer/serialized-data/getEnvName";

export const DevTools = React.memo(() => {
  const isSSR = useIsSSR();
  if (getIsProduction() || isE2E() || isSSR) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <LazyDevTools />
    </Suspense>
  );
});
