import { IUpdateCartRequestV2 } from "@faire/web-api/faire/carts/IUpdateCartRequestV2";
import cloneDeep from "lodash/cloneDeep";

import {
  IBrandCartForUpdate,
  ICartItemForUpdate,
  ILeanCartItemV2,
} from "@faire/retailer/stores/domain/CartStore/consts";

export const buildUpdateCartProto = (
  item: ILeanCartItemV2,
  properties?: Partial<ICartItemForUpdate>,
  includeTesterInfo?: boolean
) => {
  return ICartItemForUpdate.build({
    token: item.token,
    brand_token: item.brand_token,
    product_token: item.product_token,
    product_option_token: item.product_option_token,
    quantity: item.quantity,
    customizations: item.customizations ?? [],
    includes_tester: includeTesterInfo ? item.includes_tester : undefined,
    tester_price:
      includeTesterInfo && item.includes_tester ? item.tester_price : undefined,
    ...properties,
  });
};

export const createBrandCartForUpdate = (
  brand_token?: string | undefined,
  items?: Array<IUpdateCartRequestV2.ICartItemForUpdate>,
  saved_for_later?: boolean | undefined,
  requested_ship_date?: number | undefined
): IBrandCartForUpdate => {
  return {
    brand_token,
    saved_for_later,
    items: items ?? [],
    requested_ship_date,
    token: undefined,
  };
};

export const generateBrandCartForUpdates = (
  baseBrandCarts: IBrandCartForUpdate[],
  items: IUpdateCartRequestV2.ICartItemForUpdate[],
  requestShipDates?: Record<string, number>
): IBrandCartForUpdate[] => {
  const brandCarts = cloneDeep(baseBrandCarts);

  items.forEach((baseItem) => {
    const item = cloneDeep(baseItem);
    const brandCart = brandCarts.find(
      (brandCart: IBrandCartForUpdate) =>
        brandCart.brand_token === item.brand_token
    );

    if (brandCart) {
      brandCart.items.push(item);
    } else {
      brandCarts.push(createBrandCartForUpdate(item.brand_token, [item]));
    }
  });

  if (requestShipDates) {
    Object.keys(requestShipDates).forEach((brand_token) => {
      const brandCart = brandCarts.find(
        (brandCart: IBrandCartForUpdate) =>
          brandCart.brand_token === brand_token
      );

      const requestShipDate = requestShipDates[brand_token];
      if (brandCart) {
        brandCart.requested_ship_date = requestShipDate;
      } else {
        brandCarts.push(
          createBrandCartForUpdate(
            brand_token,
            undefined,
            undefined,
            requestShipDate
          )
        );
      }
    });
  }

  return brandCarts;
};
