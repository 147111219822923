import { lazy } from "react";

import { handleComponentLoadError } from "@faire/retailer/lib/loadChunkErrors";

export const LazyFirstOrderIncentiveFab = lazy(() =>
  import(
    "@faire/retailer/components/FirstOrderIncentiveFab/FirstOrderIncentiveFab" /* webpackChunkName: "retailer-foi-fab" */
  )
    .then((m) => ({ default: m.FirstOrderIncentiveFab }))
    .catch(handleComponentLoadError)
);
