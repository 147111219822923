import React from "react";

/**
 * Returns true if this in the same stack of a hook, class component, or function component.
 */
const isInReact = () => {
  const isInReact19 =
    // @ts-expect-error this is probably very prescient
    React.__CLIENT_INTERNALS_DO_NOT_USE_OR_WARN_USERS_THEY_CANNOT_UPGRADE?.H;

  const isInReact18 =
    // @ts-expect-error this is hopefully less prescient
    React.__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED
      ?.ReactCurrentDispatcher.current;

  return isInReact19 || isInReact18;
};

/**
 * If the context is non-null and available, return it.
 *
 * Context is available when we are in the same stack of a hook or function component.
 */
export const maybeUse = <T>(context: React.Context<T> | null): T | null => {
  if (!context) {
    return null;
  }

  // We shoehorn context into our globals getter. Globals are sometimes accessed during
  // module initialization. In those cases, we can't use the context.
  if (!isInReact()) {
    return null;
  }

  // In React 19, `use()` provides the functionality we need.
  //
  // This will, however, warn when you use it in a memo or hook, which is very noisy.
  // We need a plan to move away from this.
  // if ("use" in React) {
  //   return React.use(context);
  // }

  // @ts-expect-error -- in React 18, _currentValue stores the value for the context in the current React frame.
  // This isn't a public API, but it's the only way to get the value outside of context APIs.
  // We should migrate everything here to their own contexts.
  return context?._currentValue;
};
