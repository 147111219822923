import { getLocationOrThrow } from "@faire/web/common/globals/getLocation";
import { getWindowOrThrow } from "@faire/web/common/globals/getWindow";

import { setTimeoutIfPossible } from "@faire/retailer/lib/async-functions/setTimeoutIfPossible";
import { PageLoadEvent } from "@faire/retailer/services/performance/const";

export const getCurrentUrl = () =>
  [getLocationOrThrow().pathname, getLocationOrThrow().search].join("");

export const getEventStartTime = (event: PageLoadEvent): number | undefined => {
  const entryList: PerformanceEntryList | undefined =
    performance?.getEntriesByName(event);
  return entryList?.[0]?.startTime;
};

export const waitForFrameDelayBelowThreshold = (
  frameTimeThresholdMs: number,
  onThresholdReached: () => void
): void => {
  let previousTickTime = performance.now();
  const onTick = () => {
    if (performance.now() - previousTickTime < frameTimeThresholdMs) {
      onThresholdReached();
    } else {
      previousTickTime = performance.now();
      scheduleTick();
    }
  };

  const scheduleTick = () => {
    const { document, requestAnimationFrame } = getWindowOrThrow();
    if (document.visibilityState === "visible") {
      requestAnimationFrame(onTick);
    } else {
      setTimeoutIfPossible(onTick, 1);
    }
  };

  scheduleTick();
};
