export const getBrandPageLoadKey = (options: {
  brandToken: string;
  query: string;
  filters: string;
  pageNumber: string;
  collections?: string;
}) => {
  const { brandToken, query, filters, pageNumber, collections } = options;
  return `${brandToken}-${query}-${filters}-${pageNumber}-${collections}`;
};
