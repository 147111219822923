import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { useIsOnCartPage } from "@faire/retailer/hooks/cart/useIsOnCartPage";
import { CartsTabs } from "@faire/retailer/stores/domain/CartStore/consts";

import {
  buildServerCartSummaryQueryKey,
  useRelevantQueryParams,
  SERVER_CART_SUMMARY_QUERY_KEY_PREFIX,
} from "./utils";

export const useInvalidateServerCartSummary = () => {
  const queryClient = useQueryClient();
  const queryParams = useRelevantQueryParams();
  const isOnCartPage = useIsOnCartPage();

  const invalidateServerCartSummary = useCallback(
    (selectedBrandTokens: string[], activeTab: CartsTabs) => {
      if (isOnCartPage) {
        /**
         * Refetch ALL cart summaries without selected brands.
         */
        queryClient.invalidateQueries({
          queryKey: buildServerCartSummaryQueryKey(
            queryParams,
            [],
            activeTab,
            true
          ),
          refetchType: "all",
        });
        /**
         * Refetch ACTIVE cart summaries with the selected brands.
         */
        return queryClient.invalidateQueries({
          queryKey: buildServerCartSummaryQueryKey(
            queryParams,
            selectedBrandTokens,
            activeTab,
            true
          ),
          refetchType: "active",
        });
      } else {
        /**
         * When we're not on the cart page, we don't want the server action to refetch
         * at all since the request will fail. Instead we'll remove the query from the cache
         * so that the `initialData` from the server component is used instead.
         */
        queryClient.removeQueries({
          queryKey: [SERVER_CART_SUMMARY_QUERY_KEY_PREFIX],
          exact: false,
        });
        return Promise.resolve();
      }
    },
    [queryClient, queryParams, isOnCartPage]
  );

  const invalidateActiveServerCartSummary = useCallback(
    (selectedBrandTokens: string[]) =>
      invalidateServerCartSummary(selectedBrandTokens, CartsTabs.ALL_BRAND),
    [invalidateServerCartSummary]
  );
  const invalidateSavedServerCartSummary = useCallback(
    (selectedBrandTokens: string[]) =>
      invalidateServerCartSummary(
        selectedBrandTokens,
        CartsTabs.SAVED_FOR_LATER
      ),
    [invalidateServerCartSummary]
  );

  return {
    invalidateActiveServerCartSummary,
    invalidateSavedServerCartSummary,
  };
};
