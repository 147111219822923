import { createMemoryHistory, MemoryHistory } from "history";
import { usePathname } from "next/navigation";
import { useCallback, useLayoutEffect, useRef, useState } from "react";

import { sanitizeUrl } from "@faire/retailer/app/_lib/routing/sanitizeUrl";
import { useSearchParamsString } from "@faire/retailer/app/_lib/routing/useSearchParamsString";
import { initializeBrandRedirects } from "@faire/retailer/initializers/brandRedirects";

export const useMemoryHistory = () => {
  const [memoryHistory] = useState(() => {
    // In order to make this function pure so that it doesn't recreate the history
    // object multiple times, we attach the history object to window and check its
    // value before calling createMemoryHistory
    //
    // Notice that this function gets executed in server environment too where it
    // can create multiple history objects (as window doesn't exist), but it is
    // expected and should not cause any issues.

    // eslint-disable-next-line @faire/ssr-no-restricted-globals
    const isWindowDefined = typeof window !== "undefined";

    const history: MemoryHistory =
      // @ts-expect-error -- These are globals (we want to get rid of globals).
      // eslint-disable-next-line @faire/ssr-no-restricted-globals
      (isWindowDefined && window["memoryHistory"]) ||
      createMemoryHistory({
        initialEntries: ["/"], // this should be corrected by correctMemoryHistoryInitialEntry in CSR & SSR
      });

    if (isWindowDefined) {
      // @ts-expect-error -- These are globals (we want to get rid of globals).
      // eslint-disable-next-line @faire/ssr-no-restricted-globals
      window["memoryHistory"] = history;
    }
    return history;
  });

  useCorrectMemoryHistoryInitialEntry(memoryHistory);
  useMakerToBrandRedirect(memoryHistory);

  return memoryHistory;
};

/** Extracted this part out to bootstrap memory history in unit tests */
export const useCorrectMemoryHistoryInitialEntry = (
  memoryHistory: MemoryHistory
) => {
  const didInit = useRef(false);
  const pathname = usePathname();
  const searchParamsString = useSearchParamsString();

  const correctMemoryHistoryInitialEntry = useCallback(() => {
    if (!didInit.current) {
      memoryHistory.replace(
        sanitizeUrl({
          pathname,
          search: searchParamsString,
        })
      );
    }

    didInit.current = true;
  }, [memoryHistory, pathname, searchParamsString]);

  /**
   * we want to run the following side effect outside a useEffect hook,
   * so that we can ensure the url is updated during SSR, and is
   * consistent between SSR and client rendering.
   */
  correctMemoryHistoryInitialEntry();
};

/**
 * Ensure "maker" is replaced with "brand" in the URL
 * Consistent with src/initializeApp.ts#L105 in the legacy app
 */
const useMakerToBrandRedirect = (memoryHistory: MemoryHistory) => {
  useLayoutEffect(() => {
    initializeBrandRedirects(memoryHistory);
  }, [memoryHistory]);
};
