export enum PageLoadEvent {
  // These timings live in the html document
  docStart = "docStart", // when we parse the top of the document head
  docPreData = "docPreData", // immediately before we load serialized data
  docDataStart = "docDataStart", // when we start loading serialized data
  docDataEnd = "docDataEnd", // when we finish loading serialized data
  docVendorBundleStart = "docVendorBundleStart", // when we evaluate the vendor bundles
  docMainBundleStart = "docMainBundleStart", // when we evaluate the main app bundle
  docMainBundleEnd = "docMainBundleEnd", // when we evaluate the main app bundle
  docEnd = "docEnd", // when we've parsed the entire document

  // These timings live in the react app
  appStart = "appStart", // triggered when main() begins
  appInitialized = "appInitialized", // app routes are initialized, ReactDOM render begins
  firstPaint = "firstPaint", // when the header was added to DOM
  pageStart = "pageStart", // when main view component added to DOM
  pageInteractive = "pageInteractive", // when the page becomes interactive
  primaryFetchStart = "primaryFetchStart", // when we fire a view-specific blocking fetch
  primaryFetchEnd = "primaryFetchEnd", // when we fire a view-specific blocking fetch
  firstMeaningfulPaint = "firstMeaningfulPaint", // similar to web vitals LCP.

  // These are durations, composed of timings above
  durationParse = "durationParse",
  durationFetchData = "durationFetchData",
  durationDocData = "durationDocData",
  durationVendorBundle = "durationVendorBundle",
  durationMainBundle = "durationMainBundle",
  durationFirstPaint = "durationFirstPaint",
  durationRender = "durationRender",
}

export const pageLoadDurations = {
  [PageLoadEvent.durationParse]: [PageLoadEvent.docStart, PageLoadEvent.docEnd],
  [PageLoadEvent.durationFetchData]: [
    PageLoadEvent.docPreData,
    PageLoadEvent.docDataStart,
  ],
  [PageLoadEvent.durationDocData]: [
    PageLoadEvent.docDataStart,
    PageLoadEvent.docDataEnd,
  ],
  [PageLoadEvent.durationVendorBundle]: [
    PageLoadEvent.docVendorBundleStart,
    PageLoadEvent.docMainBundleStart,
  ],
  [PageLoadEvent.durationMainBundle]: [
    PageLoadEvent.docMainBundleStart,
    PageLoadEvent.docMainBundleEnd,
  ],
  [PageLoadEvent.durationFirstPaint]: [
    PageLoadEvent.appInitialized,
    PageLoadEvent.firstPaint,
  ],
  [PageLoadEvent.durationRender]: [
    PageLoadEvent.appInitialized,
    PageLoadEvent.firstMeaningfulPaint,
  ],
} as const;
