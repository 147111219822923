import { useViewport } from "@faire/web/ui/hooks/useViewport";
import React, { Suspense } from "react";

import { useShouldShowMobileAppBanner } from "@faire/retailer/components/App/useShouldShowMobileAppBanner";
import { LazyMobileAppSnackbar } from "@faire/retailer/components/MobileAppSnackbar";

export const MobileAppSnackbarWrapper = () => {
  const { isMobile } = useViewport();
  const shouldShowMobileAppBanner = useShouldShowMobileAppBanner();

  if (!isMobile || !shouldShowMobileAppBanner) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <LazyMobileAppSnackbar />
    </Suspense>
  );
};
