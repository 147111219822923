import { getSettingAndAssignMillionLintOptimizationLocation } from "@faire/retailer/settings/getSettingAndAssignMillionLintOptimizationLocation";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { create } from "zustand";
import { useShallow } from "zustand/react/shallow";

// import { getSettingAndAssignMillionLintOptimization } from "@faire/retailer/settings/getSettingAndAssignMillionLintOptimization";

// Temporarily disabled to figure out regression in UPLT in MILLION_LINT_OPTIMIZATION_241118

interface Location {
  hash: string;
  key?: string;
  pathname: string;
  search: string;
  state: unknown;
}

const useRawZustandLocation = create<Location>(() => ({
  search: "",
  pathname: "",
  hash: "",
  state: null,
  key: "",
}));

export const useZustandLocation = (selector: (state: Location) => unknown) => {
  const shouldMemoMillionLint =
    getSettingAndAssignMillionLintOptimizationLocation() &&
    // eslint-disable-next-line no-process-env
    process.env.NODE_ENV !== "test";
  if (shouldMemoMillionLint) {
    // eslint-disable-next-line react-compiler/react-compiler, react-hooks/rules-of-hooks
    useRawZustandLocation(useShallow(selector));
  }

  return shouldMemoMillionLint
    ? // eslint-disable-next-line react-compiler/react-compiler
      useRawZustandLocation.getState()
    : // eslint-disable-next-line react-compiler/react-compiler, react-hooks/rules-of-hooks
      useLocation();
};

export const ZustandLocationProvider = () => {
  const location = useLocation();

  React.useEffect(() => {
    useRawZustandLocation.setState(location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location.search,
    location.pathname,
    location.key,
    location.hash,
    location.state,
  ]);
  return null;
};
