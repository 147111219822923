import { lazy } from "react";

import { handleComponentLoadError } from "@faire/retailer/lib/loadChunkErrors";

export const LazyAppDownloadNudge = lazy(() =>
  import(
    "@faire/retailer/components/AppDownloadNudge/AppDownloadNudge" /* webpackChunkName: "retailer-mobile-app-download-nudge" */
  )
    .then((m) => ({ default: m.AppDownloadNudge }))
    .catch(handleComponentLoadError)
);
