import { NEXTJS_RSC_LIBP_MIGRATION_241121 } from "@faire/web-api/indigofair/settings/NEXTJS_RSC_LIBP_MIGRATION_241121";
import { NEXTJS_RSC_LOGGED_IN_BRAND_PAGE_240826 } from "@faire/web-api/indigofair/settings/NEXTJS_RSC_LOGGED_IN_BRAND_PAGE_240826";
import { getGlobalProperty } from "@faire/web/common/globals/getGlobalProperty";

import { getSettingValueWithoutAssignment } from "@faire/retailer/settings/__internal__/getSetting";
import {
  useAssignSetting,
  useSetting,
} from "@faire/retailer/settings/__internal__/useSetting";

export const getSettingRscLibp = (rscParam: string | undefined): boolean => {
  if (rscParam === "1") {
    return true;
  }
  if (rscParam === "0" || !getGlobalProperty("isNextJS", false)) {
    return false;
  }
  return getSettingValueWithoutAssignment(
    NEXTJS_RSC_LOGGED_IN_BRAND_PAGE_240826,
    false
  );
};

export const useAssignSettingRscLibp = () =>
  useAssignSetting(NEXTJS_RSC_LOGGED_IN_BRAND_PAGE_240826);

const useGetSettingRscLibp = () => {
  return useSetting(NEXTJS_RSC_LOGGED_IN_BRAND_PAGE_240826, false);
};

/** @knipignore */
export const useGetSettingRscComponentMigration = (): boolean => {
  const rscLibp = useGetSettingRscLibp();
  const componentMigration = useSetting(
    NEXTJS_RSC_LIBP_MIGRATION_241121,
    false
  );
  return rscLibp && componentMigration;
};
