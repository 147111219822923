import { CurrencyUnit } from "@faire/web-api/faire/currency/CurrencyUnit";
import { IFaireMoney } from "@faire/web-api/indigofair/data/IFaireMoney";
import { IOpenWithFaireCreditOfferRange } from "@faire/web-api/indigofair/data/IOpenWithFaireCreditOfferRange";
import { IRetailer } from "@faire/web-api/indigofair/data/IRetailer";
import { IRetailerLaunchWithFaire as IOpenWithFaire } from "@faire/web-api/indigofair/data/IRetailerLaunchWithFaire";
import { getGlobalProperty } from "@faire/web/common/globals/getGlobalProperty";
import { singletonGetter } from "@faire/web/common/singletons/getter";
import { createStoreHook } from "@faire/web/ui/hooks/useStore";
import differenceInDays from "date-fns/differenceInDays";
import { computed } from "mobx";

import { BUSINESS_TYPE } from "@faire/retailer/consts/BUSINESS_TYPE";
import { getSettingOpenWithFaireAvailableForCountry } from "@faire/retailer/settings/getSettingOpenWithFaireAvailableForCountry";
import { getSettingOpenWithFaireOfferedTerms } from "@faire/retailer/settings/getSettingOpenWithFaireOfferedTerms";
import { RetailerStore } from "@faire/retailer/stores/domain/RetailerStore";

const DEFAULT_TERMS_LIMIT_RANGE = IOpenWithFaireCreditOfferRange.build({
  min_terms_offered: IFaireMoney.build({
    amount_cents: 500000,
    currency: CurrencyUnit.USD,
  }),
  medium_terms_offered: IFaireMoney.build({
    amount_cents: 1000000,
    currency: CurrencyUnit.USD,
  }),
  max_terms_offered: IFaireMoney.build({
    amount_cents: 2000000,
    currency: CurrencyUnit.USD,
  }),
});

/**
 * @deprecated prefer to use `useOpenWithFaireStore` or `withStores` instead
 */
export class OpenWithFaireStore {
  /**
   * @trackfunction
   */
  static get = singletonGetter(OpenWithFaireStore);

  @computed
  private get retailer(): IRetailer | undefined {
    return RetailerStore.get().retailer;
  }

  @computed
  get hasPendingOwfUwRequest(): boolean {
    return !!this.retailer?.launch_with_faire?.has_pending_owf_uw_request;
  }

  @computed
  get appliedAt(): number | undefined {
    return this.retailer?.launch_with_faire?.applied_at;
  }

  @computed
  get status(): keyof typeof IOpenWithFaire.Status | undefined {
    return this.retailer?.launch_with_faire?.status;
  }

  @computed
  get applicationType():
    | keyof typeof IOpenWithFaire.ApplicationType
    | undefined {
    return this.retailer?.launch_with_faire?.application_type;
  }

  @computed
  get isEligible(): boolean {
    return this.status === IOpenWithFaire.Status.ELIGIBLE;
  }

  @computed
  get isAccepted(): boolean {
    return this.status === IOpenWithFaire.Status.ACCEPTED;
  }

  @computed
  get isEligibleWithNoInitialTerms(): boolean {
    return this.isEligible && !RetailerStore.get().isRetailerOnNetTerms;
  }

  @computed
  get hasApplicationInProgress(): boolean {
    return this.status === IOpenWithFaire.Status.APPLICATION_IN_PROGRESS;
  }

  @computed
  get canApply(): boolean {
    return this.isEligible || this.hasApplicationInProgress;
  }

  @computed
  private get canSeeOwFEntrypoints(): boolean {
    return !!this.retailer?.launch_with_faire?.show_entrypoints;
  }

  @computed
  get showOwFEntrypointEligibleOnly(): boolean {
    return this.isEligible && this.canSeeOwFEntrypoints;
  }

  @computed
  get showOwFEntrypoint(): boolean {
    return this.canApply && this.canSeeOwFEntrypoints;
  }

  @computed
  get hasApplicationPendingReview(): boolean {
    return this.status === IOpenWithFaire.Status.APPLIED;
  }

  @computed
  get isOnHold(): boolean {
    return (
      this.status === IOpenWithFaire.Status.ON_HOLD ||
      this.status === IOpenWithFaire.Status.ON_HOLD_NO_LEASE
    );
  }

  @computed
  get hasApplied(): boolean {
    return this.status === IOpenWithFaire.Status.APPLIED || this.isOnHold;
  }

  @computed
  get isTreatmentVisible(): boolean {
    return this.canApply || this.hasApplied;
  }

  @computed
  private get offeredTermsRange(): IOpenWithFaireCreditOfferRange {
    const businessType =
      RetailerStore.get().retailer?.business_category ??
      IRetailer.BusinessCategory.BRICK_AND_MORTAR;
    return (
      getSettingOpenWithFaireOfferedTerms()?.[businessType] ??
      DEFAULT_TERMS_LIMIT_RANGE
    );
  }

  @computed
  get offeredMinTerms(): IFaireMoney {
    return (
      this.offeredTermsRange.min_terms_offered ??
      DEFAULT_TERMS_LIMIT_RANGE.min_terms_offered!
    );
  }

  @computed
  get offeredMediumTerms(): IFaireMoney {
    return (
      this.offeredTermsRange.medium_terms_offered ??
      DEFAULT_TERMS_LIMIT_RANGE.medium_terms_offered!
    );
  }

  @computed
  get offeredMaxTerms(): IFaireMoney {
    return (
      this.offeredTermsRange.max_terms_offered ??
      DEFAULT_TERMS_LIMIT_RANGE.max_terms_offered!
    );
  }

  @computed
  get isRetailerStartedThisYear(): boolean {
    return (
      this.retailer?.reported_creation_time ===
      IRetailer.ReportedCreationTime.STARTED_THIS_YEAR
    );
  }

  @computed
  private get isWithinSixtyDaysProcessing(): boolean {
    const applicationProcessedAt =
      this.retailer?.launch_with_faire?.application_processed_at;

    if (!applicationProcessedAt) {
      return false;
    }

    const currentDate = new Date();
    return differenceInDays(currentDate, applicationProcessedAt) <= 60;
  }

  @computed
  private get showHeaderForProcessedRetailer() {
    return this.isWithinSixtyDaysProcessing;
  }

  @computed
  get showOwFInHeader(): boolean {
    return (
      (this.canSeeOwFEntrypoints && this.isTreatmentVisible) ||
      this.showHeaderForProcessedRetailer
    );
  }

  @computed
  get isOwFExpired(): boolean {
    return this.status === IOpenWithFaire.Status.EXPIRED;
  }

  @computed
  get isRejectedForOwF(): boolean {
    return this.status === IOpenWithFaire.Status.REJECTED;
  }

  @computed
  get isNotEligibleForOwF(): boolean {
    return (
      this.status === undefined ||
      this.status === IOpenWithFaire.Status.NOT_ELIGIBLE
    );
  }

  @computed
  get showOwFR2RCopyToNotEligible(): boolean {
    return (
      this.isNotEligibleForOwF &&
      getGlobalProperty("owfReferralCodeV2") !== undefined
    );
  }

  /**
   * Returns true if retailer can be referred for credit. Achieved by clicking a referral link sent by another retailer
   */
  canBeReferred = (retailerBusinessType?: BUSINESS_TYPE): boolean => {
    let retailerBusinessCategory = this.retailer?.business_category;
    if (!retailerBusinessCategory && retailerBusinessType) {
      retailerBusinessCategory = businessTypeToCategory(retailerBusinessType);
    }

    if (retailerBusinessCategory === undefined) {
      return false;
    }

    return getSettingOpenWithFaireAvailableForCountry();
  };
}

const businessTypeToCategory = (businessType: BUSINESS_TYPE) => {
  switch (businessType) {
    case BUSINESS_TYPE.POP_UP_STORE:
      return IRetailer.BusinessCategory.POP_UP;
    case BUSINESS_TYPE.ONLINE_ONLY:
      return IRetailer.BusinessCategory.ONLINE_ONLY;
    case BUSINESS_TYPE.BRICK_AND_MORTAR_SHOP:
      return IRetailer.BusinessCategory.BRICK_AND_MORTAR;
    case BUSINESS_TYPE.OTHER:
      return IRetailer.BusinessCategory.BUSINESS_OTHER;
    case BUSINESS_TYPE.CONSUMER:
    default:
      return undefined;
  }
};

export const useOpenWithFaireStore = createStoreHook(OpenWithFaireStore);
