import { useQuery as useBrandQuery } from "@faire/web-api/api/brand/brandToken/get-hooks";
import { match as matchProductRoute } from "@faire/web-api/routes/product/productToken/match";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { getBrand } from "@faire/retailer/serialized-data/getBrand";
import { getFaireDirectBrandAlias } from "@faire/retailer/serialized-data/getFaireDirectBrandAlias";
import { getFaireDirectBrandToken } from "@faire/retailer/serialized-data/getFaireDirectBrandToken";
import {
  useAssignSettingFaireDirectSignUpFromMarketplace,
  useSettingFaireDirectSignUpFromMarketplace,
} from "@faire/retailer/settings/useSettingFaireDirectSignUpFromMarketplace";
import { useBrandStore } from "@faire/retailer/stores/domain/BrandStore";
import { useLeanProductQuery } from "@faire/retailer/views/product/utils/useLeanProductQuery";

import {
  determineIsFaireDirectActiveInLoggedOut,
  handleFaireDirectSignUpFromMarketplaceAssignment,
} from "./determineIsFaireDirectActiveInLoggedOut";

export const useFaireDirectBrand = () => {
  const faireDirectBrandToken = getFaireDirectBrandToken() ?? "";
  /**
   * Checking whether Faire Direct is 'active' is derived from being on `/direct` (as a logged-out user)
   * or having the `fdb` query parameter appended. This check is only reliable and meaningful in the logged-out
   * state because in logged-in Faire Direct presence is determined through the relevant active incentive.
   */
  const location = useLocation();

  const productToken = matchProductRoute(location.pathname)?.params
    .productToken;
  // If we're on the PDP, we need to get the /api/product/productToken response here in order to
  // handle proper 'FD from marketplace' assignment. Note that this query should not kick off a new request,
  // but will instead read from the cache as we've already fetched this product on the PDP.
  const { data: productPageResponse } = useLeanProductQuery(productToken);

  const settingFaireDirectSignUpFromMarketplace =
    useSettingFaireDirectSignUpFromMarketplace();
  const assignFaireDirectSignUpFromMarketplace =
    useAssignSettingFaireDirectSignUpFromMarketplace();
  const isFaireDirectActiveInLoggedOut = settingFaireDirectSignUpFromMarketplace
    ? determineIsFaireDirectActiveInLoggedOut(location)
    : !!faireDirectBrandToken;

  useEffect(() => {
    handleFaireDirectSignUpFromMarketplaceAssignment(
      location,
      assignFaireDirectSignUpFromMarketplace,
      productPageResponse
    );
  }, [location, assignFaireDirectSignUpFromMarketplace, productPageResponse]);

  const { prefetchedFullBrand } = useBrandStore(["prefetchedFullBrand"]);
  const { data: faireDirectBrand } = useBrandQuery(
    faireDirectBrandToken,
    undefined,
    {
      enabled: isFaireDirectActiveInLoggedOut,
      initialData: () => {
        if (getBrand()?.token === faireDirectBrandToken) {
          return getBrand();
        }

        if (prefetchedFullBrand?.token === faireDirectBrandToken) {
          return prefetchedFullBrand;
        }

        return undefined;
      },
      staleTime: 1000 * 60 * 5,
    }
  );

  const faireDirectBrandAlias =
    faireDirectBrand?.token_alias ?? getFaireDirectBrandAlias() ?? "";

  return {
    faireDirectBrandToken,
    isFaireDirectActiveInLoggedOut,
    faireDirectBrand,
    faireDirectBrandAlias,
  };
};
