import { IAlertBannerPageType } from "@faire/web-api/indigofair/data/IAlertBannerPageType";
import { route as checkoutSuccessRoute } from "@faire/web-api/routes/checkout/success/orderToken";
import { route as confirmIdentityRoute } from "@faire/web-api/routes/confirm-identity";
import { route as netTermsRoute } from "@faire/web-api/routes/net-terms";
import { route as myTeamsRoute } from "@faire/web-api/routes/retailer/settings/my-team";
import { route as paymentMethodsRoute } from "@faire/web-api/routes/retailer/settings/payment-methods";
import { route as invoicesRoute } from "@faire/web-api/routes/user/account/invoices";
import { route as ordersRoute } from "@faire/web-api/routes/user/account/orders";

export const routeToAlertBannerPageTypeMap: Record<
  string,
  keyof typeof IAlertBannerPageType.Enum
> = {
  [ordersRoute]: IAlertBannerPageType.Enum.RETAILER_ORDERS_AND_REVIEWS_PAGE,
  [checkoutSuccessRoute]: IAlertBannerPageType.Enum.RETAILER_POST_ORDER_PAGE,
  [myTeamsRoute]: IAlertBannerPageType.Enum.RETAILER_MY_TEAM_PAGE,
};

export const forceRefetchAlertBannerRoutes = [
  ...Object.keys(routeToAlertBannerPageTypeMap),
  confirmIdentityRoute,
  netTermsRoute,
  paymentMethodsRoute,
  invoicesRoute,
];
