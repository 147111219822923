import { RetailerOnboardingProgress } from "@faire/web-api/faire/retailers/pos/RetailerOnboardingProgress";
import { IRetailerLinkCohortEnum } from "@faire/web-api/indigofair/retailers/pos/IRetailerLinkCohortEnum";
import { IRetailerPosLinkSettings } from "@faire/web-api/indigofair/retailers/pos/IRetailerPosLinkSettings";
import { RetailerLinkState } from "@faire/web-api/indigofair/retailers/pos/RetailerLinkState";
import { useQuery as useLocationsQuery } from "@faire/web-api/retailers-pos-api/pos-locations/get-hooks";
import { useQuery as usePosSummaryQuery } from "@faire/web-api/retailers-pos-api/summary/get-hooks";
import uniqBy from "lodash/uniqBy";
import { useMemo } from "react";

import { getPOSName } from "@faire/retailer/features/PointOfSale/utils/getPOSName";
import { useIsLoggedInRetailer } from "@faire/retailer/lib/isLoggedInRetailer";

export const usePOSSummary = () => {
  const isLoggedIn = useIsLoggedInRetailer();

  const {
    data: summaryData,
    isPending: isLoading,
    isError,
    refetch,
  } = usePosSummaryQuery(undefined, {
    staleTime: 1000 * 60 * 5,
    enabled: isLoggedIn,
  });

  const summary = useMemo(() => summaryData?.pos_link_summary, [summaryData]);
  const settings = useMemo(() => summary?.settings, [summary]);
  const type = summary?.link_ref?.type;
  const name = type ? getPOSName(type) : "";
  const hasLinkedState =
    summary?.retailer_link_state === RetailerLinkState.RETAILER_LINK_LINKED;
  const hasLinkingState =
    summary?.retailer_link_state === RetailerLinkState.RETAILER_LINK_LINKING;
  const hasFinishedOnboarding =
    summary?.onboarding_progress === RetailerOnboardingProgress.V2_FINISHED ||
    summary?.onboarding_progress === RetailerOnboardingProgress.FINISHED;
  const hasAutomaticInventory =
    summary?.settings?.inventory_update ===
    IRetailerPosLinkSettings.InventoryUpdate.AUTOMATIC;
  const disabledSettings = summary?.disabled_settings;
  const retailerLinkCohort = summary?.retailer_link_cohort
    ? IRetailerLinkCohortEnum.RetailerLinkCohort[summary?.retailer_link_cohort]
    : IRetailerLinkCohortEnum.RetailerLinkCohort.RETAILER_LINK_COHORT_UNKNOWN;
  const isLinkedCohort = [
    IRetailerLinkCohortEnum.RetailerLinkCohort.ACTIVE,
    IRetailerLinkCohortEnum.RetailerLinkCohort.INACTIVE,
  ].includes(retailerLinkCohort);

  const { data: locationsData } = useLocationsQuery({
    staleTime: 1000 * 60 * 5,
    enabled: hasLinkedState,
  });

  const locations = useMemo(() => {
    const selectedLocation = settings?.selected_location;
    if (selectedLocation) {
      return uniqBy(
        [selectedLocation, ...(locationsData?.locations ?? [])],
        (location) => location.id
      );
    } else {
      return locationsData?.locations ?? [];
    }
  }, [locationsData, settings]);

  return {
    isLoading,
    isError,
    refetch,
    type,
    name,
    businessName: summary?.business_name,
    shopifyId: summary?.link_ref?.shopify_merchant_id,
    reviewCount: summary?.require_review_count ?? 0,
    linkedCount: summary?.linked_count ?? 0,
    archivedCount: summary?.archived_count ?? 0,
    matchesCount: summary?.match_suggestion_count ?? 0,
    settings,
    locations,
    isLinked: hasLinkedState || hasLinkingState,
    /**
     * This indicates whether the retailer has a functional POS connection or not,
     * which means a linked state AND a finished onboarding
     */
    hasLinkedPOS: hasLinkedState && hasFinishedOnboarding,
    onboardingProgress: summary?.onboarding_progress,
    setupProgressPercent: summary?.setup_progress_percent,
    isInventoryStale: summary?.is_inventory_stale,
    defaultWeightUnit: summary?.default_weight_unit,
    createdAt: summary?.created_at,
    showReviewBanner: summary?.show_feedback_banner,
    hasAutomaticInventory,
    disabledSettings,
    retailerLinkCohort: retailerLinkCohort,
    isLinkedCohort,
  };
};
