import { CURRENCY_TO_GEO_CONSTRAINT } from "@faire/retailer-shared/consts/CURRENCY_TO_GEO_CONSTRAINT";
import { RequestOptions } from "@faire/web-api/types";
import { Currency } from "@faire/web/common/currency/CurrencyStore";

const ROUTES_TO_CONVERT_CURRENCY = [
  /^\/api\/product\/p_[a-z0-9]+\/product-promo-messages$/,
  /^\/api\/product\/p_[a-z0-9]+/,
  /^\/api\/search\/makers-with-filters$/,
  /^\/api\/v2\/search\/brands$/,
  /^\/api\/search\/product-first-cat-nav$/,
  /^\/api\/search\/query-products$/,
  /^\/api\/v2\/products\/search/,
  /^\/api\/cart/,
  /^\/api\/v2\/carts/,
  /^\/api\/brand-view\/b_[a-z0-9]+/,
  /^\/api\/v2\/brand-view\/b_[a-z0-9]+/,
  /^\/api\/v2\/retailers\/r_[a-z0-9]+\/boards/,
];

export const isBrandPreviewInterceptorFactory =
  (getIsBrandPreview: () => boolean, getSelectedCurrency: () => Currency) =>
  (config: RequestOptions) => {
    if (getIsBrandPreview()) {
      config.headers = config.headers ?? {};
      config.headers["X-IF-BRAND-PREVIEW"] = true;
      const previewGeoConstraint =
        CURRENCY_TO_GEO_CONSTRAINT[getSelectedCurrency()];
      if (!previewGeoConstraint) {
        return config;
      }

      if (!config.subdomain || config.subdomain === "www") {
        config.headers["X-BRAND-PREVIEW-GEO-CONSTRAINT"] =
          JSON.stringify(previewGeoConstraint);
      }

      return config;
    }

    if (
      config.url &&
      ROUTES_TO_CONVERT_CURRENCY.some((route) => config.url!.match(route))
    ) {
      config.headers = config.headers ?? {};
      config.headers["X-ACCEPT-CURRENCY"] = getSelectedCurrency();
    }
    return config;
  };
