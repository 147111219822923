import { trackEvent } from "@faire/web/common/trackEvent";

import { RetailerStore } from "@faire/retailer/stores/domain/RetailerStore";

export const trackRetailerEvent = async (event: string | string[]) => {
  const { retailerToken } = RetailerStore.get();
  if (retailerToken) {
    const events: string[] = [`/retailer/${retailerToken}`].concat(event);
    return trackEvent(events);
  }

  return trackEvent(event);
};
