import {
  getDoQuerySpellcheckGivenRecordOfQueryParams,
  getFilterKeysGivenRecordOfQueryParams,
  getPageNumberGivenRecordOfQueryParams,
  getSearchQueryGivenRecordOfQueryParams,
  parseSearch,
} from "@faire/retailer-shared/search/searchRequest";

import { getSearchPageLoadKey } from "@faire/retailer/nextjs-rsc-util/getSearchPageLoadKey";
import { isSearchPageRoute } from "@faire/retailer/nextjs-rsc-util/isSearchPageRoute";
import { matchDiscoverPageRoute } from "@faire/retailer/nextjs-rsc-util/matchDiscoverPageRoute";
import { shouldShowRscLoggedInSearchPage } from "@faire/retailer/views/search-v2/util/shouldShowRscLoggedInSearchPage";
import { shouldShowRscLoggedOutSearchPage } from "@faire/retailer/views/search-v2/util/shouldShowRscLoggedOutSearchPage";

// Only request RSC payload on filter, search term, page number, and spellcheck change to URL on search and discover pages
export const getShouldRequestRSCOnSearchPage = (options: {
  prevPathname: string;
  prevSearch: string;
  pathname: string;
  search: string;
}): boolean => {
  const { prevPathname, prevSearch, pathname, search } = options;
  const prevSearchPageLoadKey = buildSearchPageLoadKey(
    prevPathname,
    prevSearch
  );
  const pageSearchLoadKey = buildSearchPageLoadKey(pathname, search);
  return (
    (shouldShowRscLoggedOutSearchPage() || shouldShowRscLoggedInSearchPage()) &&
    (isSearchPageRoute(pathname, search) !== null ||
      matchDiscoverPageRoute(pathname, search) !== null) &&
    pageSearchLoadKey !== prevSearchPageLoadKey
  );
};

const buildSearchPageLoadKey = (pathname: string, search: string) => {
  const discoverSearchTerm = matchDiscoverPageRoute(pathname, search)?.params
    .searchTerm;
  const parsedSearch = parseSearch({ search, discoverSearchTerm });
  const query = getSearchQueryGivenRecordOfQueryParams(parsedSearch);
  const filters = getFilterKeysGivenRecordOfQueryParams(parsedSearch);
  const pageNumber = getPageNumberGivenRecordOfQueryParams(parsedSearch);
  const doQuerySpellCheck =
    getDoQuerySpellcheckGivenRecordOfQueryParams(parsedSearch);
  return getSearchPageLoadKey({
    query,
    filters,
    pageNumber,
    doQuerySpellCheck,
  });
};
