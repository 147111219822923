import { QueryParams } from "@faire/web/common/query-params/QueryParams";

export const splitFiltersFromQueryParams = (
  queryParams: QueryParams | undefined
) => {
  return (
    queryParams
      ?.get("filters")
      // Only split by comma if there is no space after the comma to patch issues with commas in taxonomy filter keys
      ?.split(/,(?!\s)/)
  );
};

export const splitFiltersFromQueryParamString = (
  queryParamsString: string | undefined
) => {
  // Only split by comma if there is no space after the comma to patch issues with commas in taxonomy filter keys
  return queryParamsString?.split(/,(?!\s)/);
};
