import dismissAlertBanner from "@faire/web-api//api/alert-banner/dismiss/post";
import fetchAlertBanner, {
  QueryParameters as AlertBannerParams,
} from "@faire/web-api/api/alert-banner/get";
import type { AlertBannerType } from "@faire/web-api/indigofair/data/AlertBannerType";
import { IAlertBanner } from "@faire/web-api/indigofair/data/IAlertBanner";
import { IAlertBannerPageType } from "@faire/web-api/indigofair/data/IAlertBannerPageType";
import { IWebSocketMessage } from "@faire/web-api/indigofair/websockets/IWebSocketMessage";
import { getLocation } from "@faire/web/common/globals/getLocation";
import { makeObservable } from "@faire/web/common/makeObservable";
import { pathMatchesRoute } from "@faire/web/common/routes/RouteMatcher";
import { singletonGetter } from "@faire/web/common/singletons/getter";
import { Message } from "@faire/web/messenger/MessageChannel/MessageChannel";
import { createStoreHook } from "@faire/web/ui/hooks/useStore";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { action, observable } from "mobx";

import { setTimeoutIfPossible } from "@faire/retailer/lib/async-functions/setTimeoutIfPossible";
import { trackRetailerEvent } from "@faire/retailer/lib/trackRetailerEvent";
import { routeToAlertBannerPageTypeMap } from "@faire/retailer/packages/core/services/AlertBanner/consts";
import { getAlertBanner } from "@faire/retailer/serialized-data/getAlertBanner";

const BANNER_CACHE_TIME = 90000;

/**
 * @deprecated prefer to use `useAlertBannerStore` or `withStores` instead
 */
export class AlertBannerStore {
  /**
   * @trackfunction
   */
  static get = singletonGetter(AlertBannerStore);

  private initialLoad = true;

  @observable
  banner?: IAlertBanner;

  private refetchTimer: number | null = null;

  @observable
  clientSideBanner?: IAlertBanner;

  private onBannerChange?: () => void;

  constructor() {
    if (!isEmpty(getAlertBanner())) {
      this.banner = getAlertBanner();
    }
    makeObservable(this);
  }

  private setBanner = (banner: IAlertBanner | undefined) => {
    this.banner = banner;
    this.onBannerChange?.();
    if (banner?.type) {
      trackRetailerEvent(`alert-banner/${banner.type.toLowerCase()}/show`);
    }
  };

  @action
  setClientSideBanner = (banner: IAlertBanner) => {
    this.clientSideBanner = banner;
  };

  initialize = (onBannerChange?: () => void) => {
    this.onBannerChange = onBannerChange;
    if (!isEmpty(getAlertBanner())) {
      // Even though `this.banner` is set in the contructor,
      // we should call `this.setBanner()` to fire callbacks and tracking events.
      this.setBanner(getAlertBanner());
    }
  };

  throttleFetchBanner = (options?: { forceRefetch: boolean }) => {
    if (this.initialLoad) {
      this.initialLoad = false;
      return;
    }

    if (options?.forceRefetch) {
      this.fetch();
      return;
    }

    if (this.refetchTimer !== null) {
      return;
    }

    this.fetch();
    this.refetchTimer =
      setTimeoutIfPossible(() => {
        this.refetchTimer = null;
      }, BANNER_CACHE_TIME) ?? null;
  };

  fetch = () => {
    const location = getLocation();
    const routeKey = location
      ? Object.keys(routeToAlertBannerPageTypeMap).find((key) =>
          pathMatchesRoute(location.pathname, key)
        )
      : undefined;
    fetchAlertBanner(
      AlertBannerParams.build({
        page_type: routeKey
          ? routeToAlertBannerPageTypeMap[routeKey]
          : IAlertBannerPageType.Enum.DEFAULT,
      })
    ).then((result) => {
      if (!isEqual(result, this.banner)) {
        this.setBanner(result);
      }
    });
  };

  dismissClientSideBanner = () => {
    this.clientSideBanner = undefined;
  };

  dismiss = async (type: keyof typeof AlertBannerType) => {
    const banner = await dismissAlertBanner(type);
    this.setBanner(banner);
  };

  handleNotificationChannelMessage = (message: Message) => {
    if (
      message.type === IWebSocketMessage.Type.ALERT_BANNER_UPDATED &&
      !!message.alert_banner
    ) {
      this.fetch();
    }
  };
}

export const useAlertBannerStore = createStoreHook(AlertBannerStore);
