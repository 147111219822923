import { Message } from "@faire/web/messenger/MessageChannel/MessageChannel";
import { useCallback, useEffect } from "react";

import { CartUpdatedV2, ICartUpdateEventV2 } from "./events";
import { useInvalidateCart } from "./features/Cart/useCart";
import { useInvalidateCartItems } from "./hooks/cart/useCartItems";
import { useInvalidateCartSummary } from "./hooks/cart/useCartSummary";
import { webSocketUrls } from "./lib/webSocketUrls";
import { WebSocketChannelStore } from "./stores/WebSocketChannelStore";

export const useCartInitialization = () => {
  const { invalidateActiveCartSummary, invalidateEntireCartSummary } =
    useInvalidateCartSummary();
  const { invalidateCartItemInventory, invalidateLeanBrandsMap } =
    useInvalidateCartItems();
  const { invalidateEntireCart } = useInvalidateCart();

  const handleEvent = useCallback(
    (event: ICartUpdateEventV2) => {
      if (!event.skipItemInventoryFetch) {
        invalidateCartItemInventory();
      }
      if (!event.skipCartInvalidation) {
        invalidateEntireCart();
        invalidateEntireCartSummary();
      }
      invalidateLeanBrandsMap();
    },
    [
      invalidateEntireCartSummary,
      invalidateCartItemInventory,
      invalidateEntireCart,
      invalidateLeanBrandsMap,
    ]
  );

  /**
   * Note: this is a temporary solution to ensure that the cart inventory
   * and brand queries are refetched when the cart is updated. It should be mounted
   * once at the top of the app.
   *
   * This can be cleaned up once we migrate off of the CartUpdatedV2 app event.
   */
  useEffect(() => {
    CartUpdatedV2.subscribe(handleEvent);
    return () => {
      CartUpdatedV2.unsubscribe(handleEvent);
    };
  }, [handleEvent]);

  const handleDutiesCalculatedMessage = useCallback(
    (message: Message) => {
      if (message.type === "CART_DUTIES_UPDATED") {
        invalidateActiveCartSummary();
      }
    },
    [invalidateActiveCartSummary]
  );

  /**
   * When cart is updated, BE calls a 3rd party api to calculate related
   * duties, and send a websocket message when finishes
   */
  useEffect(() => {
    const websocket = WebSocketChannelStore.get()
      .getOrInitChannel(webSocketUrls.retailerNotification())
      ?.subscribe(handleDutiesCalculatedMessage);
    return () => {
      if (websocket) {
        WebSocketChannelStore.get()
          .getOrInitChannel(webSocketUrls.retailerNotification())
          ?.unsubscribe(websocket);
      }
    };
  }, [handleDutiesCalculatedMessage]);
};
