import { route as cartRoute } from "@faire/web-api/routes/cart";
import { RouteMatcher } from "@faire/web/common/routes/RouteMatcher";
import { useLocation } from "react-router-dom";

const cartRouteMatcher = new RouteMatcher([cartRoute]);

export const useIsOnCartPage = () => {
  const pathname = useLocation().pathname;
  return !!cartRouteMatcher.match(pathname);
};
