import { RETAILER_APP_IDENTIFIER } from "@faire/retailer-shared/consts/RETAILER_APP_IDENTIFIER";
import { replaceSubdomainInSandboxInterceptor } from "@faire/retailer-shared/web-api/replaceSubdomainInSandboxInterceptor";
import { RequestInterceptor } from "@faire/web-api/types";
import { Currency } from "@faire/web/common/currency/CurrencyStore";
import { appIdentifierInterceptorFactory } from "@faire/web/common/requests/request-interceptors/appIdentifierInterceptor";
import { e2eAuthHeaderInterceptor } from "@faire/web/common/requests/request-interceptors/e2eAuthHeaderInterceptor";
import { e2eTestTokenInterceptor } from "@faire/web/common/requests/request-interceptors/e2eTestTokenInterceptor";
import { releaseVersionHeaderInterceptor } from "@faire/web/common/requests/request-interceptors/releaseVersionHeaderInterceptor";
import { settingOverridesInterceptor } from "@faire/web/common/requests/request-interceptors/settingOverridesInterceptor";
import { timezoneHeaderInterceptor } from "@faire/web/common/requests/request-interceptors/timezoneHeaderInterceptor";
import { isNotUndefined } from "@faire/web/common/typescriptUtils";

import { isApparelRetailerInterceptorFactory } from "./isApparelRetailerInterceptor";
import { isBrandPreviewInterceptorFactory } from "./isBrandPreviewInterceptorFactory";

export const getRequestInterceptors = (params: {
  getIsApparelRetailer: () => boolean;
  getIsBrandPreview: () => boolean;
  getSelectedCurrency: () => Currency;
}): RequestInterceptor[] =>
  [
    appIdentifierInterceptorFactory(RETAILER_APP_IDENTIFIER),
    settingOverridesInterceptor,
    isApparelRetailerInterceptorFactory(params.getIsApparelRetailer),
    releaseVersionHeaderInterceptor,
    e2eAuthHeaderInterceptor,
    e2eTestTokenInterceptor,
    timezoneHeaderInterceptor,
    replaceSubdomainInSandboxInterceptor,
    isBrandPreviewInterceptorFactory(
      params.getIsBrandPreview,
      params.getSelectedCurrency
    ),
  ].filter(isNotUndefined);
