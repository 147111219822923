import { ResponseInterceptor } from "@faire/web-api/types";
import { clientReloadInterceptor } from "@faire/web/common/requests/response-interceptors/clientReloadInterceptor";
import { clientSideLatencyResponseInterceptor } from "@faire/web/common/requests/response-interceptors/clientSideLatencyResponseInterceptor";
import { errorLoggingInterceptor } from "@faire/web/common/requests/response-interceptors/errorLoggingInterceptor";
import { opentelemetryResponseInterceptor } from "@faire/web/common/requests/response-interceptors/opentelemetryResponseInterceptor";

import { darkReadsResponseInterceptor } from "@faire/retailer/initialize/dark-read/darkReadsResponseInterceptor";

export const clientResponseInterceptors: ReadonlyArray<ResponseInterceptor> =
  Object.freeze([
    errorLoggingInterceptor,
    clientReloadInterceptor,
    darkReadsResponseInterceptor,
    opentelemetryResponseInterceptor,
    clientSideLatencyResponseInterceptor,
  ]);
